import { envBase } from './environment.4goodrewards.base';
import { environment as myRaisifyEnvironment } from './environment.qa';

export const environment = {
  ...myRaisifyEnvironment,
  ...envBase,
  /**
   * The API the frontend speaks to.
   *
   * @remarks
   * NOT Earn2, but the URL to this project's sails API routes.
   */
  apiUrl: 'https://qa.4goodrewards.com/api/',
  huterra: {
    ...myRaisifyEnvironment.huterra,
    website: {
      ...myRaisifyEnvironment.huterra.website,
      url: `https://qa.4goodrewards.com/`
    }
  }
};
